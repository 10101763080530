<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div id="mapCanvas" class="myMapClass" style="width: 100%;height: 795px;"></div>
                <div class="JLm1tf-bEDTcc-GWbSKc id-omnibox-container" id="omnibox-container">
                    <div class="vasquette-margin-enabled Hk4XGb id-omnibox" id="omnibox">
                        <div id="omnibox-singlebox">
                            <div class="NaMBUd omnibox-active" role="search">
                                <v-card style="border-radius: 6px !important;" elevation="2" outlined>
                                    <v-card-text>
                                        <v-container fluid style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;margin-top: 0px !important;">
                                            <v-row>
                                                <v-col class="col-12" sm="6" md="3">
                                                    <v-radio-group v-model="rdType" dense v-on:change="resetAll()" row hide-details style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 6px !important;margin-top: 0px !important;">
                                                        <v-radio label="Sales Activity" color="info" value="sales_activity" ></v-radio>
                                                    </v-radio-group>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="3">
                                                    <v-select solo v-model="salesModel" :items="salesLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" clearable dense prepend-icon="mdi-account" class="pt-0 mt-0" hide-details style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;margin-top: 0px !important;"></v-select>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="3">
                                                    <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field solo v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" dense prepend-icon="mdi-calendar-check" hide-details class="pt-0 mt-0" style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;margin-top: 0px !important;"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="dateFrom" :min=minMonth :max=maxMonth type="month" scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                                            <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>
                                                <v-col class="col-12" sm="6" md="3">
                                                    <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field solo v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" dense prepend-icon="mdi-calendar-check" hide-details class="pt-0 mt-0" style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;margin-top: 0px !important;"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="dateTo" :min=minMonth :max=maxMonth type="month" :show-current="true" scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                                            <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>
                                                </v-col>
                                            </v-row>
                                        </v-container>                                        
                                    </v-card-text>
                                </v-card>
                                <!-- <div class="nhb85d xoLGzf Hk4XGb sbox-focus FkJ4Sc OAaR7b" id="searchbox">
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="JLm1tf-bEDTcc-GWbSKc id-omnibox-summary-container" id="omnibox-summary-container">
                    <div class="vasquette-margin-enabled Hk4XGb id-omnibox" id="omnibox">
                        <div id="omnibox-singlebox">
                            <div class="NaMBUd omnibox-active" role="search">
                                <v-card style="border-radius: 6px !important;" elevation="2" outlined>
                                    <v-card-text>
                                        <v-container fluid style="padding-bottom: 0px !important;margin-bottom: 0px !important;padding-top: 0px !important;margin-top: 0px !important;">
                                            <v-row>
                                                <v-col class="col-12" sm="12" md="12">
                                                    <span class="">Office : {{ stroffice }}</span> <br />
                                                    <span class="">Total Customer : {{ totalCustomer }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KRI',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Dashboard',
                disabled: false,
                href: '#',
                },
                {
                text: 'GIS Analysis',
                disabled: true,
                href: '#',
                },
            ],
            officeList: [],
            custList: [],
            orderList: [],
            dialogDetail: false,
            rdType: 'sales_activity',
            mymap: null,
            group1: L.featureGroup(),
            custName: '',
            custAddress: '',
            custCity: '',
            totalCustomer: '',
            modalDateFrom: false,
            dateFrom: '',
            modalDateTo: false,
            dateTo: '',
            minMonth: this.currentPeriod(),
            maxMonth: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
            stroffice: '',
            salesModel: '',
            salesLists: []
        }
    },
    created() {
    },
    async mounted(){
        this.mymap = L.map('mapCanvas').setView([-7.515104417081626, 112.42708530581592], 6)
        
        var map = L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_KMB}`, {
            maxZoom: 20,
            attribution: '<b>Viva Kencana Investindo</b><br> Mojokerto',
            id: 'mapbox/streets-v11',
            tileSize: 512,
            zoomOffset: -1
        }).addTo(this.mymap)

        this.initialize()

    },
    methods:{
        currentPeriod() {
            const current = new Date();
            current.setDate(current.getDate() - 40)

            const date = current.getFullYear() + '-' + ('0' + (current.getMonth())).slice(-2)
            return date;
        },
        resetAll(){
            this.mymap.removeLayer(this.group1)
            this.group1 = L.featureGroup()
        },
        async initialize(){

            Swal.fire({
                text: "Please Wait...",
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false 
            })

            this.dateFrom = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)
            // this.dateTo = this.currentPeriod2()
            this.dateTo = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7)

            await axios.get(`${process.env.VUE_APP_URL}/api/hst/GISAnalysis`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                })
                .then(res => {
                    
                    this.officeList = res.data.office
                    this.salesLists = res.data.sales
                    
                    for (var i = 0; i < this.officeList.length; i++) {                        

                        var marker = new L.marker([parseFloat(this.officeList[i]['lat']), parseFloat(this.officeList[i]['lng'])])
                        .bindTooltip(this.officeList[i]['office'], 
                        {
                            permanent: true, 
                            direction: 'right'
                        })
                        .on('click', this.getDetailMap)
                        .addTo(this.mymap)

                    }

                    Swal.close()

                    this.$store.dispatch('setOverlay', false)

                    
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                Swal.close()
                console.log(err);

            })

        },
        async getDetailMap(e){

            var branchOffice = e.target._tooltip._content;
            this.stroffice = ''

            this.resetAll()

            if(this.rdType == 'customer_order'){

                this.resetAll()

                Swal.fire({
                    text: "Please Wait...",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    showConfirmButton: false 
                })

                await axios.post(`${process.env.VUE_APP_URL}/api/hst/GISAnalysis/getCustomerOrder`, { 
                    office: branchOffice,
                    sales: this.salesModel ? this.salesModel : '',
                    dateFrom: new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,""),
                    dateTo: new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    if(this.mymap.hasLayer(this.group1)){
                        this.mymap.removeLayer(this.group1);
                        this.group1 = L.featureGroup()
                    }

                    this.orderList = res.data.result;
                    this.totalCustomer = res.data.total
                    this.stroffice = res.data.office

                    console.log(res.data.office);

                    for (var i = 0; i < res.data.result.length; i++) {

                        // var customer = this.orderList[i]['NamaCustomer']

                        L.circle([parseFloat(this.orderList[i]['sales_latitude'] ? this.orderList[i]['sales_latitude'] : 0), parseFloat(this.orderList[i]['sales_longitude'] ? this.orderList[i]['sales_longitude'] : 0)], {
                            color: 'red',
                            fillColor: '#f03',
                            fillOpacity: 0.5,
                            radius: 10
                        }).addTo(this.group1).on("click", this.circleClick);

                    }

                    this.mymap.addLayer(this.group1)

                    Swal.close()
                    
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err);

                })

            } else if(this.rdType == 'sales_activity'){

                this.resetAll()

                Swal.fire({
                    text: "Please Wait...",
                    allowOutsideClick: false,
                    showCancelButton: false,
                    showConfirmButton: false 
                })

                await axios.post(`${process.env.VUE_APP_URL}/api/hst/GISAnalysis/getSalesActivity`, { 
                    office: branchOffice,
                    sales: this.salesModel ? this.salesModel : '',
                    dateFrom: new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,""),
                    dateTo: new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    if(this.mymap.hasLayer(this.group1)){
                        this.mymap.removeLayer(this.group1);
                        this.group1 = L.featureGroup()
                    }

                    this.orderList = res.data.result;
                    this.totalCustomer = res.data.total
                    this.stroffice = res.data.office

                    for (var i = 0; i < res.data.result.length; i++) {

                        L.circle([parseFloat(this.orderList[i]['sales_latitude'] ? this.orderList[i]['sales_latitude'] : 0), parseFloat(this.orderList[i]['sales_longitude'] ? this.orderList[i]['sales_longitude'] : 0)], {
                            color: 'red',
                            fillColor: '#f03',
                            fillOpacity: 0.5,
                            radius: 10,
                            tesss: 'okeeee'
                        }).addTo(this.group1).on("click", this.circleClick);

                    }

                    this.mymap.addLayer(this.group1)

                    Swal.close()
                    
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err);

                })

            } else {

                Swal.fire({
                    icon: 'warning',
                    text: 'Please Select type'
                })

            }

        },
        async circleClick(e){

            // console.log(e.sourceTarget._latlng.lat);

            this.custName = ''
            this.custAddress = ''
            this.custCity = ''

            Swal.fire({
                text: "Please Wait...",
                allowOutsideClick: false,
                showCancelButton: false,
                showConfirmButton: false 
            })

            await axios.post(`${process.env.VUE_APP_URL}/api/hst/GISAnalysis/getCustomerDetail`, { 
                lat: e.sourceTarget._latlng.lat,
                lng: e.sourceTarget._latlng.lng
            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                console.log(res.data.namacustomer)
                this.custName = res.data.namacustomer
                this.custAddress = res.data.alamat
                this.custCity = res.data.city
                // this.dialogDetail = true

                Swal.close()

                Swal.fire({
                    html: "Cust. Name : " + this.custName + "<br/>" + "Cust. Address : " + this.custAddress + "<br/>" + "Cust. City : " + this.custCity + "<br/>",
                    // icon: 'su',
                })
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err);

            })

        },
    },
    watch: {
        dateFrom: function() {
            this.resetAll()
        },
        dateTo: function() {
            this.resetAll()
        },
    }

}

</script>

<style scoped>

.v-text-field {
padding-top: 10px !important;
}

.v-breadcrumbs {
padding: 5px 5px !important;
}

.badgeInfo {
font-size: 1em;
padding-bottom: 10px; 
padding-left: 10px;
}

.cardCustom{
padding-top: 10px;
}

.container{
margin-bottom: 100px !important;
}

.v-text-field >>> input {
font-size: 1em !important;
font-weight: 100 !important;
text-transform: capitalize !important;
}
.v-text-field >>> label {
font-size: 0.8em !important;
}
.v-text-field >>> button {
font-size: 0.8em !important;
}

.modal {
margin: 0 auto; 
}

.myMapClass {
z-index: 2;
}

</style>